<template>
  <v-container fluid>
    <h3 class="page-title">一番賞圖示管理</h3>
    <v-row>
      <v-col cols="12" sm="6" v-for="(key, index) in keys" :key="index">
        <v-divider class="my-3"></v-divider>
        <div class="pa-4">
          <div class="d-flex justify-space-between">
            <h3>{{ heading[key] }}</h3>
            <v-btn
              color="primary"
              dense
              hide-details
              @click="uploadImage(key)"
              :disabled="!uploadFile[key]"
            >
              儲存
            </v-btn>
          </div>
          <div>
            <h4 class="mb-3">當前圖片</h4>
            <img
              v-if="mapImage[key]?.url"
              :src="mapImage[key]?.url"
              alt=""
              class="preview-img"
            />
            <div v-else>無</div>
          </div>
        </div>
        <div class="pa-4">
          <h4 class="mb-3">更新圖片</h4>
          <v-file-input
            v-model="uploadFile[key]"
            accept="image/png, image/jpeg, image/webp"
            label="更新內容圖"
            outlined
            dense
            hide-details
            @change="(e) => getLocalImageFile(e, key)"
            class="mb-4"
          ></v-file-input>
          <div class="images-preview">
            <v-img
              v-if="uploadUrl[key]"
              :src="uploadUrl[key]"
              class="preview-img"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";

export default {
  name: "Banner",
  props: [],
  data: () => ({
    keys: ["win", "lose", "background"],
    uploadFile: {
      win: null,
      lose: null,
      background: null,
    },
    uploadUrl: {
      win: null,
      lose: null,
      background: null,
    },
    mapImage: {},
    heading: {
      win: "中獎圖示",
      lose: "安慰獎圖示",
      background: "背景圖",
    },
  }),
  computed: {},
  methods: {
    async load() {
      await this.getIchibanImages();
    },
    async getIchibanImages() {
      const { data } = await this.axios.get("/ichiban/images");
      this.mapImage = _.keyBy(data.images, "type");
    },
    getLocalImageFile(event, key) {
      this.uploadFile[key] = event;
      this.uploadUrl[key] = URL.createObjectURL(event);
    },
    async uploadImage(key) {
      const file = this.uploadFile[key];
      let formData = new FormData();
      formData.append("file", file, Date.now());

      this.$vloading.show();
      try {
        const { data } = await this.axios.post(
          `/ichiban/upload-image/${key}`,
          formData
        );
        this.mapImage[key].url = data.imageUrl;
        this.uploadFile[key] = null;
        this.uploadUrl[key] = null;

        this.$toast.success("更新成功");
      } catch (error) {
        console.log(error);
        this.$toast.error("更新失敗");
      } finally {
        this.$vloading.hide();
      }
    },
  },
  async created() {
    await this.load();
  },
};
</script>

<style lang="scss" scoped>
h3 {
  width: fit-content;
  display: inline-block;
}

.preview-img {
  width: 200px;
  height: auto;
  // width: 250px;
  // height: 300px;
  object-fit: contain;
}
</style>
